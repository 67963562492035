import React, { useEffect, useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Flex,
  Text,
  Button,
  Input,
  Box,
} from '@chakra-ui/react'

import { useLazyQuery } from '@apollo/client'

import { AUTH_MUTATIONS } from '../../components/auth/mutations/auth.mutations'

export interface ICheckoutModalModalProps {
  isOpen: boolean
  onSuccess: (coupon: any) => void
  onClose: () => void
  onSkip: () => void
}

const CheckoutModal: React.FC<ICheckoutModalModalProps> = ({
  isOpen,
  onSuccess,
  onClose,
  onSkip,
}) => {
  const [couponCode, setCouponCode] = useState<any>(null)
  const [couponResult, setCouponResult] = useState<string>('')

  const [checkCoupon] = useLazyQuery(AUTH_MUTATIONS.CHECK_COUPON, {
    fetchPolicy: 'network-only',
    onCompleted: (couponData) => {
      if (couponData?.checkCoupon?.valid) {
        setCouponResult(`You got ${couponData?.checkCoupon?.percent_off}% off!`)
      } else {
        setCouponResult('Invalid coupon')
      }
    },
    onError: () => {},
  })

  useEffect(() => {
    if (couponCode) {
      checkCoupon({
        variables: { couponId: couponCode },
      })
    } else {
      setCouponResult('')
    }
  }, [couponCode])

  const handleCouponChange = (value: string) => {
    setCouponCode(value)
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault()
    onSuccess(couponCode)
  }

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      scrollBehavior="outside"
      size={'lg'}
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        bgColor="#272727"
        color="white"
        border="none"
        borderRadius="4px"
      >
        <ModalHeader textAlign="center">Apply a coupon</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={handleSubmit}>
            <Box py={2}>
              <Text htmlFor="coupon">Coupon code (optional)</Text>
              <Input
                id="coupon"
                placeholder="CODE"
                onChange={(e) => {
                  handleCouponChange(e.target.value)
                }}
              />
              <Text>{couponResult}</Text>
            </Box>
            <Flex justifyContent="center" my={4}>
              <Button
                disabled={!couponResult || couponResult === 'Invalid coupon'}
                id="submit"
                type="submit"
                color="white"
                colorScheme="brand"
              >
                Redeem
              </Button>
              <Button onClick={onClose} ml={4} variant="outline">
                Cancel
              </Button>
              <Button onClick={onSkip} ml={4}>
                Purchase
              </Button>
            </Flex>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default CheckoutModal
